import { useEffect, useState } from "react";

export function condRender (currentDate) {
    if (currentDate === "10/05") {
        
    }
};

export function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState(null);
  
    useEffect(() => {
      let lastScrollY = window.pageYOffset;
  
      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? "down" : "up";
  
        if (
          direction !== scrollDirection &&
          (scrollY - lastScrollY > 8 || scrollY - lastScrollY < -8)
        ) {
          setScrollDirection(direction);
        }
  
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
  
      window.addEventListener("scroll", updateScrollDirection);
  
      return () => {
        window.removeEventListener("scroll", updateScrollDirection);
      };
    }, [scrollDirection]);
  
    return scrollDirection;
  };